<template>
  <!-- 充值 end -->
  <div>
    <!-- start 骨架屏 -->
    <div class="tab2 skeleton--animate" style="height: 100vh" v-if="member.length==0">
      <div class="skeleton-personal">

      </div>
      <ul class="query_list">
        <li v-for="(item, index) in menuList" :key="index">
          <div class="skeleton-query-img">

          </div>
          <div class="skeleton-query-text">

          </div>
        </li>
      </ul>
    </div>
    <!-- end 骨架屏 -->
    <div class="tab2" style="height: 100vh" v-else>
      <div class="personal">
        <div class="personal_top">
          <div class="face">

            <img src="../../assets/images/face1.png" alt="" />
          </div>
          <div class="mation">

            <h3>
              {{ member.memberInfo.memberName }}
            </h3>

            <p>
              {{ member.mobilePhoneInfo.mobilePhone }}
            </p>
          </div>
        </div>
        <div class="personal_bottom">
          <div class="charg">
            <p>话费余额</p>

            <span>{{ member.mobilePhoneInfo.callBalance }}元</span>
          </div>
          <div class="flow">
            <p>剩余流量</p>

            <span>{{member.mobilePhoneInfo.flowBalance}}G</span>
          </div>
        </div>
      </div>
      <ul class="query_list">
        <li @click="toLink(item.routeName, {})" v-for="(item, index) in menuList" :key="index">
          <div class="query_img">
            <img :src="item.icon" alt="" />
          </div>
          <p>{{ item.menuName }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex"
import API from "../../common/api/api"
import { Dialog, Toast } from "vant"
import { toLink } from "../../common/utils/router"
export default {
    name: "BusinessQuery",
    data() {
        return {
            menuList: [
                {
                    menuName: "查余额",
                    icon: require("../../assets/images/cx1.png"),
                    routeName: "balance",
                },
                {
                    menuName: "查账单",
                    icon: require("../../assets/images/cx2.png"),
                    routeName: "bill",
                },
                {
                    menuName: "查套餐余量",
                    icon: require("../../assets/images/cx3.png"),
                    routeName: "setMeal",
                },
                {
                    menuName: "查充值记录",
                    icon: require("../../assets/images/cx4.png"),
                    routeName: "record",
                },
                {
                    menuName: "查已订购业务",
                    icon: require("../../assets/images/cx5.png"),
                    routeName: "orderbusine",
                },
                {
                    menuName: "查名下号卡",
                    icon: require("../../assets/images/cx6.png"),
                    routeName: "queryCard",
                },
            ],
        }
    },
    watch: {},
    created() {
       
    },
    components: {},
    methods: {
        toLink,
    },
    computed: {
        ...mapState(["member"]),
    },
}
</script>
<style lang="scss" scoped>
//骨架屏
.skeleton-personal {
    width: 100%;
    height: px2rem(270);
    border-radius: px2rem(20);

    margin-top: px2rem(37);

    margin-bottom: px2rem(70);

    background-color: #eeeeee;
}
.skeleton-query-img {
    width: px2rem(48);
    height: px2rem(48);
    margin: 0 auto px2rem(20);
    background-color: #eeeeee;
}
.skeleton-query-text {
    width: px2rem(96);
    height: px2rem(24);
    margin: 0 auto;
    background-color: #eeeeee;
}
.skeleton--animate {
    -webkit-animation: skeleton-blink 1.2s ease-in-out infinite;
    animation: skeleton-blink 1.2s ease-in-out infinite;
}
@-webkit-keyframes skeleton-blink {
    50% {
        opacity: 0.6;
    }
}
@keyframes skeleton-blink {
    50% {
        opacity: 0.6;
    }
}
//tab2
.tab2 {
    padding: 0 px2rem(30);
}

.personal {
    width: 100%;
    height: px2rem(270);
    border-radius: px2rem(20);
    background-color: #336189;
    margin-top: px2rem(37);
    box-shadow: 0 0 px2rem(30) rgba(0, 0, 0, 0.3);
    margin-bottom: px2rem(70);
}

.personal_top {
    display: flex;
    height: px2rem(137);
    border-bottom: 1px solid #48789f;
    overflow: hidden;
}

.face {
    margin: px2rem(23) px2rem(24) 0 px2rem(20);
}

.face,
.face img {
    width: px2rem(90);
    height: px2rem(90);
    border-radius: 50%;
}

.mation {
    margin-top: px2rem(30);
}

.mation h3 {
    font-size: px2rem(30);
    color: #fff;
    font-weight: bold;
    margin-bottom: px2rem(10);
}

.mation p {
    font-size: px2rem(26);
    color: #fff;
}

.personal_bottom {
    display: flex;
    color: #fff;
}

.charg,
.flow {
    width: 50%;
    height: px2rem(78);
    text-align: center;
    margin-top: px2rem(26);
    padding-top: px2rem(5);
}

.charg {
    border-right: 2px solid #47779f;
}

.charg p,
.flow p {
    font-size: px2rem(24);
    margin-bottom: px2rem(10);
}

.charg span,
.flow span {
    display: block;
    font-size: px2rem(28);
    font-weight: 600;
}

.query_list {
    display: flex;
    flex-wrap: wrap;
}

.query_list li {
    flex: 0 0 33%;
    text-align: center;
    margin-bottom: px2rem(50);
}

.query_list .query_img {
    width: px2rem(48);
    height: px2rem(48);
    margin: 0 auto px2rem(20);
}

.query_list .query_img img {
    width: 100%;
    height: 100%;
}

.query_list p {
    font-size: px2rem(24);
    color: #333;
}
</style>